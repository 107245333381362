<template>
  <b-row class="no-gutters align-items-center">
    <b-col class="col-auto">
      <label
        for="select-admin"
        class="font-weight-normal text-white mb-0 mr-3"
        style="font-size: 1rem"
      >
        {{ `${$t("administrator")} :` }}
      </label>
    </b-col>
    <b-col class="col-auto">
      <form-select
        v-model="filterAdministrator"
        id="select-admin"
        @change="retrieveItems"
      >
        <b-form-select-option :value="null">
          {{ $t("all") }}
        </b-form-select-option>
        <b-form-select-option
          v-for="(administrator, index) in administratorOptions"
          :key="index"
          :value="administrator"
        >
          {{ $_getUserFullName(administrator.profile) }}
        </b-form-select-option>
      </form-select>
    </b-col>
  </b-row>
</template>

<script>
import userMixin from "./../../mixins/userMixin";
import { EventBus } from "./../../eventBus.js";

import FormSelect from "./../../../../console-components/BaseFormSelect";
export default {
  components: {
    FormSelect
  },
  mixins: [userMixin],
  computed: {
    filterAdministrator: {
      get() {
        return this.$store.getters["filter/getAdministratorSelected"];
      },
      set(value) {
        this.$store.commit("filter/SET_ADMINISTRATOR_SELECTED", value);
      }
    },
    administratorOptions() {
      return this.$store.getters["filter/getAdministratorOptions"];
    }
  },
  methods: {
    async retrieveItems() {
      this.resetFilters();
      const emitName = this.getPageEmitName();
      if (emitName) EventBus.$emit(emitName);
    },
    resetFilters() {
      this.$store.commit("issue/SET_FILTER_CONDOMINIUM", null);
      this.$store.commit("communication/SET_FILTER_CONDOMINIUM", null);
    },
    getPageEmitName() {
      switch (this.$route.name) {
        case "Dashboard":
          return "eventBusReloadDashboard";
        case "Issues":
          return "eventBusReloadIssues";
        case "Communications":
          return "eventBusReloadCommunications";
        case "Condominiums":
          return "eventBusReloadCondominiums";
        case "Maintainers":
          return "eventBusReloadMaintainers";
        default:
          return "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#select-admin {
  /deep/ {
    .form-group {
      max-width: 200px;
      margin-bottom: 0;
      .custom-select {
        cursor: pointer;
        background: transparent
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
          no-repeat right 0.75rem center/8px 10px;
        color: white;
        option {
          color: black;
        }
      }
    }
  }
}
</style>
