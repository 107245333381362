<template>
  <b-dropdown right>
    <template v-slot:button-content>
      <b-avatar
        class="mr-2"
        style="width: 20px;height: 20px;"
        :src="language.icon"
      ></b-avatar>
    </template>

    <b-dropdown-item
      v-for="(langItem, index) in langOptions"
      :key="index"
      @click="setLanguage(langItem.value)"
    >
      <img style="width: 20px;height: 20px;" :src="langItem.icon" />
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  watch: {
    lang: {
      handler: function(curVal) {
        this.$i18n.locale = curVal;
        this.validateLocale = curVal;
      },
      immediate: false
    }
  },
  computed: {
    lang: {
      get() {
        return this.$store.getters["general/getAppLang"];
      },
      set(value) {
        this.$store.commit("general/SET_APP_LANG", value);
      }
    },
    langOptions() {
      return this.$store.getters["general/getAppLangOptions"];
    },
    language: {
      get() {
        return this.langOptions.find(l => l.value === this.lang);
      }
    }
  },
  methods: {
    setLanguage(value) {
      this.lang = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.b-dropdown {
  /deep/ {
    .dropdown-toggle {
      background-color: transparent;
      border: unset;
      &:focus {
        box-shadow: unset;
      }
    }
    .dropdown-menu {
      min-width: auto;
    }
  }
}
</style>
