<template>
  <b-dropdown
    right
    @show="retrieveNotifications()"
    @hide="markNotificationsRead()"
  >
    <template v-slot:button-content>
      <b-avatar class="mr-2" style="width: 26px;height: 26px;" icon="bell">
      </b-avatar>
      <b-badge
        v-if="unreadNotifications"
        class="notification-badge"
        variant="primary"
        >{{ unreadNotifications }}</b-badge
      >
    </template>

    <div v-if="hasNotifications">
      <b-dropdown-item
        :active="false"
        v-for="(notification, index) in notifications"
        :key="index"
        @click="handleNotificationClick(notification)"
      >
        <b-card>
          <b-badge
            class="badge-new"
            v-if="notification.status === 'new'"
            variant="primary"
            >{{ $t("button.new") }}</b-badge
          >
          <h5>{{ notification.title }}</h5>
          <p>{{ notification.body }}</p>
        </b-card>
      </b-dropdown-item>
    </div>
    <div v-else>
      <b-dropdown-item>
        {{ $t("emptyNotificationList") }}
      </b-dropdown-item>
    </div>
  </b-dropdown>
</template>

<script>
import { authMixin } from "../administrator-console/src/mixins";
export default {
  created() {
    this.retrieveNotifications();
  },
  mixins: [authMixin],
  computed: {
    notifications() {
      return this.$store.getters["notification/notifications"];
    },
    unreadNotifications() {
      return this.$store.getters["notification/notificationNum"];
    },
    hasNotifications() {
      return this.notifications.length > 0;
    }
  },
  methods: {
    retrieveNotifications() {
      if (this.$_authUser.type === "administrator") {
        this.$store.dispatch("notification/retrieveNotifications");
      }
    },
    handleNotificationClick(notification) {
      // click_action = notificationType/:condominiumId/:id
      const [notificationType, ...action] = notification.click_action.split(
        "/"
      );
      switch (notificationType) {
        case "issue":
          this.$router.push({
            path: "/issues"
          });
          break;
        default:
          console.log(action);
          break;
      }
    },
    markNotificationsRead() {
      this.$store.dispatch("notification/markNotificationsRead");
    }
  }
};
</script>

<style lang="scss" scoped>
.b-dropdown {
  /deep/ {
    .dropdown-toggle {
      background-color: transparent;
      border: unset;
      &:focus {
        box-shadow: unset;
      }
    }
    .dropdown-menu {
      min-width: auto;
    }
  }
}

.badge-new {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
}

.notification-badge {
  position: relative;
  left: -15px;
  top: -10px;
  border-radius: 50%;
}
</style>
