<template>
  <footer class="main-footer"></footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.main-footer {
  height: 40px;
  background-color: var(--blue-dark-600);
}
</style>
