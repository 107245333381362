<template>
  <b-dropdown right>
    <template v-slot:button-content>
      <b-avatar class="mr-2" style="width: 26px;height: 26px;" :src="avatar">
      </b-avatar>
      <span :class="{ 'mr-2': hasFullName }">{{ fullName }}</span>
    </template>

    <b-dropdown-item
      v-for="(action, index) in actions"
      :key="index"
      @click="action.callbackFunc()"
    >
      {{ $t(action.text) }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    avatar: {
      type: String,
      default: ""
    },
    fullName: {
      type: String,
      default: ""
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasFullName() {
      return this.fullName !== "";
    }
  }
};
</script>

<style lang="scss" scoped>
.b-dropdown {
  /deep/ {
    .dropdown-toggle {
      background-color: transparent;
      border: unset;
      &:focus {
        box-shadow: unset;
      }
    }
    .dropdown-menu {
      min-width: auto;
    }
  }
}
</style>
