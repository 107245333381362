<template>
  <div v-resize="onResize">
    <top-menu :isLargeScreen="isLargeScreen" />
    <b-row class="no-gutters main-page-container flex-nowrap">
      <b-col class="col-auto side-menu-container">
        <side-nav-menu :isLargeScreen="isLargeScreen" />
      </b-col>
      <b-col class="pt-3 px-3 page-container">
        <router-view />
      </b-col>
    </b-row>
    <main-footer />

    <alert />
    <loader />
    <confirm-modal />
  </div>
</template>

<script>
import { EventBus } from "./../eventBus.js";

import Alert from "./../../../console-components/BaseAlert";
import Loader from "./../../../console-components/BaseLoader";
import ConfirmModal from "./../../../console-components/BaseConfirmModal";
import TopMenu from "./../components/Base/TopMenu";
import SideNavMenu from "./../components/Base/SideNavMenu";
import MainFooter from "./../components/Base/MainFooter";
export default {
  components: {
    Alert,
    Loader,
    ConfirmModal,
    TopMenu,
    SideNavMenu,
    MainFooter
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  computed: {
    isLargeScreen() {
      return this.windowWidth > 959; // breakpoint: 'md'
    }
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  directives: {
    resize: {
      inserted(el, binding) {
        const callback = binding.value;
        const options = binding.options || { passive: true };
        window.addEventListener("resize", callback, options);
        el._onResize = {
          callback,
          options
        };
        if (!binding.modifiers || !binding.modifiers.quiet) {
          callback();
        }
      },
      unbind(el) {
        if (!el._onResize) return;
        const { callback, options } = el._onResize;
        window.removeEventListener("resize", callback, options);
        delete el._onResize;
      }
    }
  },
  beforeDestroy() {
    EventBus.$off();
  }
};
</script>

<style lang="scss" scoped>
.main-page-container {
  min-height: calc(100vh - var(--top-menu-height));
}
.side-menu-container {
  /deep/ {
    .b-sidebar-outer {
      width: max-content;
      position: sticky !important;
      height: calc(100vh - var(--top-menu-height));
      z-index: var(--side-menu-z-index);
      top: var(--top-menu-height);
    }
    .b-sidebar {
      position: sticky !important;
      top: var(--top-menu-height);
      height: calc(100vh - var(--top-menu-height));
      padding-bottom: 30px;
    }
  }
}
@media screen and (max-width: 959px) {
  .side-menu-container {
    /deep/ {
      .b-sidebar-outer {
        position: fixed !important;
      }
      .b-sidebar {
        position: fixed !important;
      }
      .list-group-item.active {
        &:before {
          background: white;
          border-top: 18px solid var(--yellow);
          border-bottom: 18px solid var(--yellow);
          border-right: 18px solid var(--backdrop-dark);
          // border-right: 18px solid var(--blue-dark-500);
        }
      }
    }
  }
}
.page-container {
  padding-bottom: 100px;
}
</style>
